/* Reset default margins and ensure full height */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Sticky footer layout */
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.register-container {
  display: block;
  padding: 20px;
  background-color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  flex: 1;
}

/* Style the welcome section (now above the form) with 50px top margin */
.welcome-section {
  padding: 40px;
  text-align: center;
  color: #333;
  margin-top: 50px; /* Add 50px margin above welcome section */
}

.welcome-section h1 {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(to right, #ff5e62, #ff9966);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 20px;
}

.welcome-section p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #666;
}

.welcome-section p:last-child {
  font-weight: 600;
  color: #333;
}

/* Style the register form (below welcome section) with right margin */
.register-form {
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto 20px; /* Top and bottom margin, auto for horizontal centering, right margin added via form-content */
  max-width: 800px;
}

.form-content {
  width: 90%;
  margin: 20px 20px 20px 20px; /* Add 20px right margin to prevent touching the edge */
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
 
}

/* Adjust form layout to be wider */
.form-content form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;

  
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 5px;
  text-align: left;
}

.form-group input,
.form-group .phone-input input,
.form-group .MuiTextField-root {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group .MuiTextField-root {
  width: 100%;
}

.phone-input {
  display: flex;
  gap: 10px;
}

.country-code {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.checkbox-label {
  font-size: 1rem;
  color: #333;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Style checkboxes to match the screenshot */
.checkbox-label input[type="checkbox"] {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ddd;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"]:checked {
  background-color: #2196f3;
  border-color: #2196f3;
}

.checkbox-label input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.submit-btn {
  grid-column: 1 / -1;
  padding: 12px;
  background: linear-gradient(to right, #ff5e62, #ff9966);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
  margin-top: 20px;
}

.submit-btn:hover {
  opacity: 0.9;
}

/* Footer styling - 100% width of the screen */
footer {
  background-color: #fff;
  padding: 20px 0;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: #666;
  width: 100%; /* Full screen width */
  margin: 20px 0 0; /* Add 20px top margin */
  flex-wrap: wrap;
  gap: 20px;
}

footer a {
  color: #ff5e62;
  text-decoration: none;
  margin: 0 10px;
}

footer a:hover {
  text-decoration: underline;
}

footer .logo-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
}

footer .logo-section h3 {
  font-size: 1.2rem;
  font-weight: 700;
  background: linear-gradient(to right, #ff5e62, #ff9966);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

footer .logo-section .social-icons {
  display: flex;
  gap: 10px;
}

footer .logo-section .social-icons span {
  width: 24px;
  height: 24px;
  border: 2px solid #ff5e62;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ff5e62;

}

footer .contact-info {
  text-align: center;
}

footer .contact-info p {
  margin: 5px 0;
}

footer .message {
  text-align: right;
  margin-right: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .welcome-section {
    padding: 20px;
    text-align: center;
  }

  .register-form {
    margin: 10px auto;
  }

  .form-content {
    margin: 10px;
    padding: 15px;
  }

  .form-content form {
    grid-template-columns: 1fr;
  }

  footer {
    flex-direction: column;
    text-align: center;
  }

  footer .message {
    text-align: center;
  }
}

/* Style for the Already Have an Account link below the form */
.login-link {
  text-align: center;
  margin-top: 20px;
  font-size: 0.9rem;
  color: #666;
}

.login-link p {
  margin: 0;
}

.login-link-text {
  color: #ff5e62;
  text-decoration: none;
  font-weight: 500;
}

.login-link-text:hover {
  text-decoration: underline;
}