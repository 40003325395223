/* Reset default margins and ensure full height */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

/* Sticky footer layout */
.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.social-connect-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff; /* Remove background image for consistency */
  padding: 20px;
}

.connect-wrapper {
  width: 100%;
  max-width: 800px; /* Match registration form width */
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #333;
  background: linear-gradient(to right, #ff5e62, #ff9966);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 2rem;
}

.connect-options {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.connect-card {
  flex: 1;
  min-width: 250px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  transition: transform 0.2s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.connect-card:hover {
  transform: translateY(-5px);
}

.platform-logo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
  font-size: 2rem;
  font-weight: bold;
  color: white;
}

.meta-logo {
  background-color: #1877F2;
}

.tiktok-logo {
  background-color: #000000;
}

h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.connect-card p {
  font-size: 0.95rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.connect-btn {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
  background: linear-gradient(to right, #ff5e62, #ff9966); /* Match registration button */
}

.connect-btn:hover {
  opacity: 0.9;
}

.meta-btn {
  background-color: #1877F2;
}

.tiktok-btn {
  background-color: #000000;
}

.disabled-btn {
  background-color: #ababab;
  cursor: not-allowed;
}

.next-btn {
  background: linear-gradient(to right, #ff5e62, #ff9966);
  margin-top: 20px;
}

.welcome-message,
.connected-message {
  color: #333;
  margin-bottom: 1rem;
}

.error-message {
  color: red;
  margin-bottom: 1rem;
}

.pages-section {
  margin-top: 2rem;
  text-align: left;
}

.pages-section h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.pages-list {
  list-style: none;
  padding: 0;
}

.page-item {
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.page-item h3 {
  font-size: 1.2rem;
  margin: 0 0 0.5rem;
  color: #333;
}

.page-item p {
  margin: 0;
  font-size: 0.95rem;
  color: #666;
}

.next-button-wrapper {
  margin-top: 2rem;
  text-align: center;
}

.footer {
  background-color: #fff;
  padding: 20px 0;
  border-top: 1px solid #ddd;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: #666;
  flex-wrap: wrap;
  gap: 20px;
}

.logo-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo-section h3 {
  font-size: 1.2rem;
  font-weight: 700;
  background: linear-gradient(to right, #ff5e62, #ff9966);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons span {
  width: 24px;
  height: 24px;
  border: 2px solid #ff5e62;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ff5e62;
}

.links a {
  color: #ff5e62;
  text-decoration: none;
  margin: 0 10px;
}

.links a:hover {
  text-decoration: underline;
}

.contact-info {
  text-align: center;
}

.contact-info p {
  margin: 5px 0;
}

.message {
  text-align: right;
}

/* Responsive design */
@media (max-width: 768px) {
  .connect-wrapper {
    width: 85%;
    padding: 20px;
  }

  .connect-options {
    flex-direction: column;
    gap: 1.5rem;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  .footer {
    flex-direction: column;
    text-align: center;
  }

  .message {
    text-align: center;
  }
}

.connected-message {
  margin-top: 20px;
}